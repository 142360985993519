<template>
  <div id="precios">
      <section class="section-price p-5">
          <div class="container">
              <div class="row justify-content-center mb-4 mb-lg-6 text-white">
                  <div class="col-12 col-lg-9 text-center">
                      <h2 class="fw-light mb-4 title">Elija una página para su negocio</h2>
                  </div>
              </div>

              <div class="row">
                  <div class="col-12 col-lg-6 col-xl-3 d-flex align-items-stretch">
                    <div class="card shadow-lg p-3 border-gray-300 rounded-md mb-3 px-2 text-center" style="width: 18rem;">
                        <div class="card-header border-0 bg-white p-3">
                            <h2 class="h3 text-primary display-2 mb-2">Landing Page</h2>

                            <span class="d-block">
                                <span class="display-5 text-dark fw-bold">
                                    <span class="align-top font-medium pricing">$</span>
                                    3,600
                                </span>

                                <span class="d-block text-gray font-small">
                                    / Pesos
                                </span>
                            </span>
                        </div>

                        <div class="card-body">
                            <ul class="list-unstyled">
                                <li class="list-item pb-4">
                                    <strong>1</strong>
                                    Dominio primer año
                                </li>

                                <li class="list-item pb-4">
                                    Hosting Primer año
                                </li>

                                <li class="list-item pb-4">
                                    Landing page
                                </li>

                                <li class="list-item pb-4">
                                    Página de una sola sección
                                </li>
                                <li class="list-item pb-4">
                                    Pocisionamiento SEO
                                </li>
                                <li class="list-item pb-4">
                                    Soporte técnico
                                </li>

                            </ul>
                        </div>
                    </div>
                  </div>

                  <div class="col-12 col-lg-6 col-xl-3 d-flex align-items-stretch">
                     <div class="card shadow-lg p-3 border-gray-300 rounded-md mb-3 px-2 text-center" style="width: 18rem;">
                        <div class="card-header border-0 bg-white p-3">
                            <h2 class="h3 text-primary mb-2">Básica</h2>

                            <span class="d-block">
                                <span class="display-5 text-dark fw-bold">
                                    <span class="align-top font-medium">$</span>
                                    6,500
                                </span>

                                <span class="d-block text-gray font-small">
                                    / Pesos
                                </span>
                            </span>
                        </div>

                        <div class="card-body">
                            <ul class="list-unstyled">
                                <li class="list-item pb-4">
                                    Página a la medida de la empresa
                                </li>

                                <li class="list-item pb-4">
                                    Página de una sola sección
                                </li>

                                <li class="list-item pb-4">
                                    <strong>1</strong>
                                    Dominio Primer año
                                </li>

                                <li class="list-item pb-4">
                                    Hosting Primer año
                                </li>
                                <li class="list-item pb-4">
                                    Botón de Whatsapp
                                </li>
                                <li>
                                    Enlace a Redes Sociales
                                </li>

                            </ul>
                        </div>
                    </div>
                  </div>

                  <div class="col-12 col-lg-6 col-xl-3 d-flex align-items-stretch">
                      <div class="card shadow-lg border-gray-300 rounded-md mb-3 px-2 text-center" style="width: 18rem;">
                        <div class="card-header border-0 bg-white p-3">
                            <h2 class="h3 text-primary mb-2">Profesional</h2>

                            <span class="d-block">
                                <span class="display-5 text-dark fw-bold">
                                    <span class="align-top font-medium">$</span>
                                    14,900
                                </span>

                                <span class="d-block text-gray font-small">
                                    / Pesos
                                </span>
                            </span>
                        </div>

                        <div class="card-body card-profesional">
                            <ul class="list-unstyled mb-2">
                                <li class="list-item pb-1">
                                    Página Web a la medida de la empresa
                                </li>

                                <li class="list-item pb-3">
                                    <strong>5</strong>
                                    Secciones
                                </li>

                                <li class="list-item pb-3">
                                    <strong>1</strong>
                                    Dominio Primer año
                                </li>

                                <li class="list-item pb-3">
                                    Hosting Primer año
                                </li>
                                <li class="list-item pb-3">
                                    Soporte Técnico
                                </li>
                                <li class="list-item pb-3">
                                    <strong>10</strong>
                                    Correos Personalizados
                                </li>
                                <li class="list-item pb-3">
                                    Botón Whatsapp y Redes sociales
                                </li>
                                <li class="list-item pb-3">
                                    Formulario de contacto
                                </li>
                                <li class="list-item pb-3">
                                    Enlace a Redes Sociales
                                </li>
                                <li class="list-item pb-3">
                                    Posibilidad de Descargar PDF con Menu
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                  </div>


                  <div class="col-12 col-lg-6 col-xl-3 d-flex align-items-stretch">
                      <div class="card shadow-lg p-3 border-gray-300 rounded-md mb-3 px-2 text-center" style="width: 18rem;">
                        <div class="card-header border-0 bg-white p-3">
                            <h2 class="h3 text-primary mb-2">E-commerce</h2>
                            <p>Desde</p>

                            <span class="d-block">
                                <div class="ribbon">Más popular</div>
                                <span class="display-5 text-dark fw-bold">
                                    <span class="align-top font-medium">$</span>
                                    22,000
                                </span>

                                <span class="d-block text-gray font-small">
                                    / Pesos
                                </span>
                            </span>
                        </div>

                        <div class="card-body">
                            <ul class="list-unstyled mb-4">
                                <li class="list-item pb-4">
                                    Diseñamos tú tienda en línea 
                                </li>

                                <li class="list-item pb-4">
                                    Pasarela de Pago (Mercado Pago, PayPal, Tarjetas de Crédito)
                                </li>

                                <li class="list-item pb-4">
                                    Catalogo de Productos
                                </li>

                                <li class="list-item pb-4">
                                    <strong>1</strong>
                                    Dominio Primer año
                                </li>
                                <li class="list-item pb-4">
                                    Hosting Primer año
                                </li>
                                <li class="list-item pb-4">     
                                    Soporte Técnico
                                </li>
                                <li class="list-item pb-4">
                                    Formulario de Contacto
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                  </div>
              </div>
          </div>
      </section>
  </div>
</template>

<script>
export default {
    name: 'Precing',
}
</script>

<style scoped>
    .section-price {
        background-color: #2E2D41;
    }

    .title {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
    }

    .text-primary {
        color: #F6971B !important;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 2em !important;
    }

    .align-top {
        vertical-align: top !important;
    }

    .ribbon {
        width: 130px;
        height: 32px;
        font-size: 12px;
        text-align: center;
        color: #fff;
        font-weight: bold;
        box-shadow: 0px 2px 3px rgba(136, 136, 136, 0.25);
        background: #4dbe3b;
        transform: rotate(45deg);
        position: absolute;
        right: -42px;
        top: 15px;
        padding-top: 7px; 
    }

    .font-medium {
        font-size: 1.25rem;
        font-weight: 300;
    }

    .fw-bold {
        font-weight: 600 !important;
    }

    .btn-primary {
        color: #fff;
        background-color: #242e4c;
        border-color: #242e4c;
        box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%), 0 1px 1px rgb(23 31 56 / 8%) !important;
        border-radius: 1rem;
        transition: all .2s ease;
        font-size: 1rem;
        padding: .8rem;
    }

    .btn-secondary {
        color: #fff;
        background-color: #F6971B;
        border-color: #F6971B;
        box-shadow: inset 0 1px 0 rgb(255 255 255 / 15%), 0 1px 1px rgb(23 31 56 / 8%) !important;
        border-radius: 1rem;
        transition: all .2s ease;
        font-size: 1rem;
        padding: .8rem;
    }

    .card {
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
        max-width: 100% !important;
    }

    .card:hover {
        margin-top: -.25rem;
        margin-bottom: .25rem;
        box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
    }

    /* Hover Effects on Card */

    @media (min-width: 992px) {
        .card:hover {
            margin-top: -.25rem;
            margin-bottom: .25rem;
            box-shadow: 0 1rem 2rem 0 rgba(0, 0, 0, 0.3);
        }

        .card:hover {
            opacity: 1;
        }
    }
</style>