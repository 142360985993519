<template>
  <div>
      <footer class="text-center text-lg-start bg-footer text-muted section-footer">
        <!-- Section: Social media -->
        <section
          class="d-flex justify-content-center justify-content-lg-between"
        >
          
          <!-- Right -->
        </section>
        <!-- Section: Social media -->
      
        <!-- Section: Links  -->
        <section class="">
          <div class="container text-center text-md-start mt-5">
            <!-- Grid row -->
            <div class="row mt-3">
              <!-- Grid column -->
              <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                <!-- Content -->
                <h6 class="text-uppercase fw-bold mb-4">
                  <img src="../assets/img/logo_makro_creativo_web-blanco.svg" alt="logo makro creativo" class="img-fluid img-responsive">
                </h6>
              </div>
              <!-- Grid column -->
      
              <!-- Grid column -->
              <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                <!-- Links -->
                <h6 class="title-footer-access fw-bold mb-4">
                  Acceso rápido
                </h6>
                <p>
                  <a href="#home" class="text-reset text-none text-dark text-decoration-none access">Home</a>
                </p>
                <p>
                  <a href="#how-are" class="text-reset text-none text-dark text-decoration-none access">Nosotros</a>
                </p>
                <p>
                  <a href="#services" class="text-reset text-none text-dark text-decoration-none access">Servicios</a>
                </p>
                <p>
                  <a href="#precios" class="text-reset text-none text-dark text-decoration-none access">Precios</a>
                </p>
                <p>
                  <a href="#contact" class="text-reset text-none text-dark text-decoration-none access">Contacto</a>
                </p>
              </div>
              <!-- Grid column -->
      
              <!-- Grid column -->
              <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                <!-- Links -->
                <h6 class="fw-bold mb-4 title-footer-services">
                  Servicios
                </h6>
                <p>
                  <a href="#!" class="text-reset text-none text-dark text-decoration-none">Mercadotecnia Digital</a>
                </p>
                <p>
                  <a href="#!" class="text-reset text-none text-dark text-decoration-none">Consultoría y Capacitación</a>
                </p>
                <p>
                  <a href="#!" class="text-reset text-none text-dark text-decoration-none">Creación de Páginas Web</a>
                </p>
                <p>
                  <a href="#!" class="text-reset text-none text-dark text-decoration-none">Manejo de Redes Sociales</a>
                </p>
                <p>
                  <a href="#!" class="text-reset text-none text-dark text-decoration-none">Branding</a>
                </p>
                <p>
                  <a href="#!" class="text-reset text-none text-dark text-decoration-none">E-commerce y Tiendas en Línea</a>
                </p>
              </div>
              <!-- Grid column -->
      
              <!-- Grid column -->
              <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <!-- Links -->
                <h6 class="title-footer-contact fw-bold mb-4">
                  Contacto
                </h6>
                <p class="text-dark">33 3149 8145</p>
                <p class="text-dark">
                  hola@makrocreativo.com
                </p>
                <p class="text-dark">Col. Jardines del Bosque GDL</p>
                <p class="text-dark">Guadalajara Jalisco</p>
              </div>
              <!-- Grid column -->
            </div>
            <!-- Grid row -->
          </div>

        </section>
        <!-- Section: Links  -->
      
        <!-- Copyright -->
        <div class="text-center p-5 text-dark" style="background-color: #FABB6E;">
            Copyright 2021 © - 
          <a class="text-reset fw-bold text-none text-dark" href="">Makro Creativo. Todos los Derechos Reservados</a>
        </div>
        <!-- Copyright -->
      </footer>
  </div>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style scoped>
    .bg-footer {
        background-color: #FABB6E;
    }

    .title-footer-contact {
        color: #1A140C;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
    }

    .text-dark {
        color: #1A140C !important;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
    }

    .title-footer-services {
        color: #1A140C !important;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
    }

    .title-footer-access {
        color: #1A140C !important;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
    }

    .access {
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
    }
</style>