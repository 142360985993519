<template>
  <div>
      <section class="section-images-marks p-5"> 
          <div class="container">
              <div class="row">
                  <div class="col-md-4-col-sm-12 col-lg-4 col-xl-4 col-xxl-4 mt-4">
                    <div class="card shadow-lg">
                        <img src="https://cdn.jsdelivr.net/gh/PaulMaldonado/img-web/logos-marcas/logos-02.svg" alt="Logos de marcas" class="card-img-top img-fluid shadow-lg">
                    </div>
                  </div>

                  <div class="col-md-4-col-sm-12 col-lg-4 col-xl-4 col-xxl-4 mt-4">
                      <div class="card shadow-lg">
                          <img src="https://cdn.jsdelivr.net/gh/PaulMaldonado/img-web/logos-marcas/logos-03.svg" alt="Logos de marcas" class="card-img-top img-fluid shadow-lg">
                      </div>
                  </div>

                  <div class="col-md-4-col-sm-12 col-lg-4 col-xl-4 col-xxl-4 mt-4">
                      <div class="card shadow-lg">
                          <img src="https://cdn.jsdelivr.net/gh/PaulMaldonado/img-web/logos-marcas/logos-04.svg" alt="Logos de marcas" class="card-img-top img-fluid shadow-lg">
                      </div>
                  </div>

                  <div class="col-md-4-col-sm-12 col-lg-4 col-xl-4 col-xxl-4 mt-4">
                      <div class="card shadow-lg">
                          <img src="https://cdn.jsdelivr.net/gh/PaulMaldonado/img-web/logos-marcas/logos-05.svg" alt="Logos de marcas" class="card-img-top img-fluid shadow-lg">
                      </div>
                  </div>

                  <div class="col-md-4-col-sm-12 col-lg-4 col-xl-4 col-xxl-4 mt-4">
                      <div class="card shadow-lg">
                          <img src="https://cdn.jsdelivr.net/gh/PaulMaldonado/img-web/logos-marcas/logos-06.svg" alt="Logos de marcas" class="card-img-top img-fluid shadow-lg">
                      </div>
                  </div>

                  <div class="col-md-4-col-sm-12 col-lg-4 col-xl-4 col-xxl-4 mt-4">
                      <div class="card shadow-lg">
                          <img src="https://cdn.jsdelivr.net/gh/PaulMaldonado/img-web/logos-marcas/logos-07.svg" alt="Logos de marcas" class="card-img-top img-fluid shadow-lg">
                      </div>
                  </div>

                  <div class="col-md-4-col-sm-12 col-lg-4 col-xl-4 col-xxl-4 mt-4">
                      <div class="card shadow-lg">
                          <img src="https://cdn.jsdelivr.net/gh/PaulMaldonado/img-web/logos-marcas/logos-08.svg" alt="Logos de marcas" class="card-img-top img-fluid shadow-lg">
                      </div>
                  </div>

                  <div class="col-md-4-col-sm-12 col-lg-4 col-xl-4 col-xxl-4 mt-4">
                      <div class="card shadow-lg">
                          <img src="https://cdn.jsdelivr.net/gh/PaulMaldonado/img-web/logos-marcas/logos-09.svg" alt="Logos de marcas" class="card-img-top img-fluid shadow-lg">
                      </div>
                  </div>

                  <div class="col-md-4-col-sm-12 col-lg-4 col-xl-4 col-xxl-4 mt-4">
                      <div class="card shadow-lg">
                          <img src="https://cdn.jsdelivr.net/gh/PaulMaldonado/img-web/logos-marcas/logos-10.svg" alt="Logos de marcas" class="card-img-top img-fluid shadow-lg">
                      </div>
                  </div>

                  <div class="col-md-4-col-sm-12 col-lg-4 col-xl-4 col-xxl-4 mt-4">
                      <div class="card shadow-lg">
                          <img src="https://cdn.jsdelivr.net/gh/PaulMaldonado/img-web/logos-marcas/logos-11.svg" alt="Logos de marcas" class="card-img-top img-fluid shadow-lg">
                      </div>
                  </div>

                  <div class="col-md-4-col-sm-12 col-lg-4 col-xl-4 col-xxl-4 mt-4">
                      <div class="card shadow-lg">
                          <img src="https://cdn.jsdelivr.net/gh/PaulMaldonado/img-web/logos-marcas/logos-12.svg" alt="Logos de marcas" class="card-img-top img-fluid shadow-lg">
                      </div>
                  </div>

                  <div class="col-md-4-col-sm-12 col-lg-4 col-xl-4 col-xxl-4 mt-4">
                      <div class="card shadow-lg">
                          <img src="https://cdn.jsdelivr.net/gh/PaulMaldonado/img-web/logos-marcas/logos-13.svg" alt="Logos de marcas" class="card-img-top img-fluid shadow-lg">
                      </div>
                  </div>

                  <div class="col-md-4-col-sm-12 col-lg-4 col-xl-4 col-xxl-4 mt-4">
                      <div class="card shadow-lg">
                          <img src="https://cdn.jsdelivr.net/gh/PaulMaldonado/img-web/logos-marcas/logos-14.svg" alt="Logos de marcas" class="card-img-top img-fluid shadow-lg">
                      </div>
                  </div>

                  <div class="col-md-4-col-sm-12 col-lg-4 col-xl-4 col-xxl-4 mt-4">
                      <div class="card shadow-lg">
                          <img src="https://cdn.jsdelivr.net/gh/PaulMaldonado/img-web/logos-marcas/logos-15.svg" alt="Logos de marcas" class="card-img-top img-fluid shadow-lg">
                      </div>
                  </div>

                  <div class="col-md-4-col-sm-12 col-lg-4 col-xl-4 col-xxl-4 mt-4">
                      <div class="card shadow-lg">
                          <img src="https://cdn.jsdelivr.net/gh/PaulMaldonado/img-web/logos-marcas/logos-16.svg" alt="Logos de marcas" class="card-img-top img-fluid shadow-lg">
                      </div>
                  </div>

                  <div class="col-md-4-col-sm-12 col-lg-4 col-xl-4 col-xxl-4 mt-4">
                      <div class="card shadow-lg">
                          <img src="https://cdn.jsdelivr.net/gh/PaulMaldonado/img-web/logos-marcas/logos-17.svg" alt="Logos de marcas" class="card-img-top img-fluid shadow-lg">
                      </div>
                  </div>

                  <div class="col-md-4-col-sm-12 col-lg-4 col-xl-4 col-xxl-4 mt-4">
                      <div class="card shadow-lg">
                          <img src="https://cdn.jsdelivr.net/gh/PaulMaldonado/img-web/logos-marcas/logos-18.svg" alt="Logos de marcas" class="card-img-top img-fluid shadow-lg">
                      </div>
                  </div>

                  <div class="col-md-4-col-sm-12 col-lg-4 col-xl-4 col-xxl-4 mt-4">
                      <div class="card shadow-lg">
                          <img src="https://cdn.jsdelivr.net/gh/PaulMaldonado/img-web/logos-marcas/logos-19.svg" alt="Logos de marcas" class="card-img-top img-fluid shadow-lg">
                      </div>
                  </div>

              </div>
          </div>
      </section>

      <a href="https://api.whatsapp.com/send?phone=523327154184&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20sus%20servicios." class="float" target="_blank">
        <i class="fab fa-whatsapp my-float"></i>
      </a>
  </div>
</template>

<script>
export default {
    name: 'ImagesMarks'
}
</script>

<style scoped>
    .section-images-marks {
        background-color: #FFFFFF;
    }

    .title-marks {
        font-weight: bold;
        font-family: 'Poppins';
        text-transform: uppercase;
        background: linear-gradient(to right, #2E2D41 0%, #F49422 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 72px !important;
    }

    .card img {
        height: 200px !important;
    }

    .card {
        transition: all 0.2s ease !important;
        cursor: pointer !important;
    }

    .card:hover {
        box-shadow: 5px 6px 6px 2px #e9ecef !important;
        transform: scale(1.1) !important;
    }

    .float{
        position:fixed;
        width:60px;
        height:60px;
        bottom:40px;
        right:40px;
        background-color:#25d366;
        color:#FFF;
        border-radius:50px;
        text-align:center;
        font-size:30px;
        box-shadow: 2px 2px 3px #999;
        z-index:100;
    }

    .my-float{
        margin-top:16px;
    }
</style>