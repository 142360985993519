<template>
  <div>
      <Mark />
  </div>
</template>

<script>
import Mark from '@/components/Mark.vue'

export default {
    name: 'Marks',
    components: {
        Mark,
    }
}
</script>